<template>
  <div>
    <material-list-add-new
      :is-add-new-material-sidebar-active.sync="isAddNewMaterialSidebarActive"
      @refetch-data="refetchData"
    /> 

    <b-card no-body class="mb-0" >
      <div class="m-2">
        <b-row> 
          <b-col cols="12" md="12">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-group>
                <div class="d-flex align-items-center">
                  <label class="mr-1">Search</label>
                  <b-form-input
                    v-model="searchTerm"
                    placeholder="Search"
                    type="text"
                    class="d-inline-block mr-1"
                  />
                  <b-button
                    variant="primary"
                    @click="isAddNewMaterialSidebarActive = true">
                    <span class="text-nowrap">Add Material</span>
                  </b-button>
                </div>
              </b-form-group>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="mx-2 mb-2"> 
        <b-row>
            <b-col> 
              <vue-good-table
              :columns="columns"
              :rows="rows"
              :rtl="direction"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm }"
              class="table b-table"
              :pagination-options="{
                enabled: true,
                perPage:pageLength
              }" >
                <template
                  slot="table-row"
                  slot-scope="props"
                >
                  <span v-if="props.column.field === 'material_created_at'" class="text-nowrap" >  
                    <span class="text-nowrap">{{ moment(props.row.material_created_at).format("DD MMM yyyy") }}</span>
                  </span>
                
                <span v-else-if="props.column.field === 'material_status'">
                  <b-badge :variant="statusVariant(props.row.material_status)">
                    {{ props.row.material_status }}
                  </b-badge>
                </span>
                <span v-else-if="props.column.field === 'action'">
                  <span>
                    <b-dropdown
                      variant="link"
                      toggle-class="text-decoration-none"
                      no-caret
                    >
                      <template v-slot:button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="text-body align-middle mr-25"
                        />
                      </template>
                      <b-dropdown-item :to="{ name: 'apps-material-edit', params: { id: props.row.material_id } }">
                        <feather-icon
                          icon="Edit2Icon"
                          class="mr-50"
                        />
                        <span>Edit</span>
                      </b-dropdown-item>
                      <b-dropdown-item @click="confirmText(props.row)" >
                        <feather-icon
                          icon="TrashIcon"
                          class="mr-50"
                        />
                        <span>Delete</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </span>
                </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['15','25','50','100']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
            </b-col> 
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BFormSelect,
  BBadge, BDropdown, BDropdownItem, BPagination,BFormGroup
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import materialStoreModule from '../materialStoreModule'
import MaterialListAddNew from './MaterialListAddNew.vue'
import { VueGoodTable } from 'vue-good-table'
import moment from 'moment';

export default {
  components: { 
    MaterialListAddNew,
    VueGoodTable,
    moment,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormSelect,
    BFormGroup,
    vSelect,
  },
  data() {
    return {
      pageLength: 15,
      dir: false, 
      columns: [
        {
          label: 'Name',
          field: 'material_name',
        },
        {
          label: 'Remark',
          field: 'material_remark',
        },  
        {
          label: 'Status',
          field: 'material_status',
        },
        {
          label: 'Created Date',
          field: 'material_created_at',
        },
        {
          label: 'Actions',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
      status: [{
        1: 'Active',
        2: 'Disabled', 
      },
      { 
        1: 'light-success',
        2: 'light-info', 
      }],
    }
  },
  methods:{
    moment,
    confirmText(recordRow) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
           
          recordRow.material
          _status='Deleted';
          store
            .dispatch('app-material/updateMaterial', recordRow)
            .then(response => {
              this.refetchData();
              this.$swal({
                icon: 'success',
                title: 'Deleted!',
                text: 'Record has been deleted.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
            .catch(() => {
              toast({
                component: ToastificationContent,
                props: {
                  title: 'Error processing',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
          }
           
      })
    },
    refetchData(){
      store
      .dispatch('app-material/fetchMaterials', { })
      .then(response => {
        this.rows = response.data;          
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching materials list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    },
  },
  computed: {
    
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Active      : 'light-success', 
        Disabled      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) { 
        this.dir = true
        return this.dir
      } 
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.refetchData();
  },
  setup() {
    const MATERIAL_APP_STORE_MODULE_NAME = 'app-material'

    // Register module
    if (!store.hasModule(MATERIAL_APP_STORE_MODULE_NAME)) store.registerModule(MATERIAL_APP_STORE_MODULE_NAME, materialStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MATERIAL_APP_STORE_MODULE_NAME)) store.unregisterModule(MATERIAL_APP_STORE_MODULE_NAME)
    })

    const isAddNewMaterialSidebarActive = ref(false)
 
    return { 
      // Sidebar
      isAddNewMaterialSidebarActive, 
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
